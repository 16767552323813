<template>
    <v-container style="margin-right: -40px;">

        <v-row>

            <v-col class="d-none d-sm-block" sm="1"></v-col>

            <v-col sm="10">
            
                <div
                style="
                max-width: 100%;
                margin: auto;
                padding-bottom: 20px;
                "
                >


                    <v-card style="margin-bottom: 15px; box-shadow: 0 0 18px #0000003d background: #95aaea;
                            background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                            filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" elevation="7" 
                        >
                        <div style="padding: 10px" class="overline">
                            <h4>Manual Product Catalogue</h4>
                        </div>
                    </v-card>

            
                    <v-card style=" widthmargin-bottom: 10px; box-shadow: 0 0 18px #0000003d; border-radius: 5px; padding: 13px" elevation="5">
                        <div>
                            
                            <div class="elementor-column-wrap elementor-element-populated">
                                <div class="elementor-widget-wrap">
                                    <div
                                        class="elementor-element elementor-element-d3c92f0 elementor-widget elementor-widget-text-editor"
                                        data-id="d3c92f0"
                                        data-element_type="widget"
                                        data-widget_type="text-editor.default"
                                        >
                                        
                                        <div class="elementor-widget-container">
                                            <div class="elementor-text-editor elementor-clearfix">
                                                
                                                <div style="background: #95aaea; padding-top: 5px; padding-bottom: 5px; padding-left: 7px; width: 320px; margin-bottom: 20px;
                                                    background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                                                    >A) Store Product Catalogue
                                                </div>

                                                <p style="font-size: 15px;">
                                                    1. Log in to LCPay Merchant Dashboard
                                                </p>

                                                <p style="font-size: 15px;">
                                                    2. Open "Product List" section in Product Catalogue menu.
                                                </p>
                                                
                                                <p style="font-size: 15px;">
                                                    3. Click on "here" to go to your product catalogue website
                                                </p>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="border: 1px solid #c8ced3; max-width: 80%" :src="require(`../assets/here.jpg`)"/>
                                                </div>

                                                <div style="background: #95aaea; padding-top: 5px; padding-bottom: 5px; padding-left: 7px; width: 320px; margin-bottom: 20px;
                                                    background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                                                    >B) Store Setting
                                                </div>


                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    4. Open "Store Setting" section in Product Catalogue menu.
                                                </p>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    5. Fill in your "Store Title" and "Store Description" to appear on your catalogue website
                                                </p>


                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="border: 1px solid #c8ced3; max-width: 100%" :src="require(`../assets/store_enter.jpg`)"/>
                                                </div>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="border: 1px solid #c8ced3; max-width: 100%" :src="require(`../assets/store_name.jpg`)"/>
                                                </div>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    6. Fill in your "Address" and "Operation Hour" to appear on your footer catalogue website
                                                </p>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="border: 1px solid #c8ced3; max-width: 100%" :src="require(`../assets/store_address.jpg`)"/>
                                                </div>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="border: 1px solid #c8ced3; max-width: 60%" :src="require(`../assets/store_address_pic.jpg`)"/>
                                                </div>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    7. Click on "TROLLEY-CART" icon to add the product to cart
                                                </p>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="max-width: 100%" :src="require(`../assets/store.jpg`)"/>
                                                </div>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    8. An alert will appear indicate the product is successfully added to cart
                                                </p>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="max-width: 100%" :src="require(`../assets/add_cart.jpg`)"/>
                                                </div>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    9. Below is an example of cart section :
                                                </p>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    10. Click on "cross-mark" icon to remove product from cart
                                                </p>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    11. Click on "proceed to checkout" button to proceed into payment page
                                                </p>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="max-width: 50%" :src="require(`../assets/cart.jpg`)"/>
                                                </div>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    12. Below is an example of product payment page :
                                                </p>

                                                <p style=" margin-top: 20px;font-size: 15px;">
                                                    13. Fill in all information given
                                                </p>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="max-width: 90%" :src="require(`../assets/payment.jpg`)"/>
                                                </div>

                                                <div style="margin-top: 40px; background: #95aaea; padding-top: 5px; padding-bottom: 5px; padding-left: 7px; width: 320px; margin-bottom: 40px;
                                                    background: -moz-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: -webkit-linear-gradient(top, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    background: linear-gradient(to bottom, #95aaea 0%, #6c7fbb 39%, #6c7fbb 40%, #6c7fbb 41%, #6a7db9 42%, #6a7db9 42%, #586ba5 62%, #4e609a 93%);
                                                    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#95aaea', endColorstr='#4e609a', GradientType=0); border-radius: 5px; color: #fff;" 
                                                    >C) Setup URL page link after finish payment
                                                </div>

                                                <p style="font-size: 15px;">
                                                    1. Log in to LCPay Merchant Dashboard
                                                </p>

                                                <p style="font-size: 15px;">
                                                    2. Click on "API" to set up your page link
                                                </p>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="border: 1px solid #c8ced3; max-width: 70%" :src="require(`../assets/logo-link.jpg`)"/>
                                                </div>

                                                <p style="font-size: 15px;">
                                                    3. You can paste your facebook business link or your LCPay store link. It will direct to page link after finish payment.
                                                </p>

                                                <div  style="margin-bottom: 30px; margin-left: 20px; margin-top: 20px; background: transparent; width: 530px">
                                                     <img style="border: 1px solid #c8ced3; max-width: 90%" :src="require(`../assets/shopping-link.jpg`)"/>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-card>
            
                </div>
            </v-col>

            <v-col class="d-none d-sm-block" sm="1"></v-col>



        </v-row>

</v-container>
</template>

<script>
    export default {

    }
</script>

<style scoped>
.profile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 40px;
    color: rgb(167, 167, 167);
    font-weight: 600;
}
</style>